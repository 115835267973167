<template>
  <div>
    <div class="order-form selector desk-only" data-margin-top="20">
      <ClientOnly>
        <BookingFormTop type="desktop" />
      </ClientOnly>
      <ContactsBlock />
    </div>
    <div class="order-form mobile-only">
      <ClientOnly>
        <BookingFormTop type="mobile" />
      </ClientOnly>
      <BookingFormBottom />
    </div>
  </div>
</template>
